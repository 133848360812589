const routes = [{
        name: "AgentCarriers",
        path: '/agents/carriers',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/AgentCarriers.vue'),
        meta: {
            title: 'Agent Carriers'
        },
    },
    {
        name: "AgentProducts",
        path: '/agents/products',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/Products.vue'),
        meta: {
            title: 'Agent Products'
        },
    },
    {
        name: "AgentCarrierProducts",
        path: '/agents/products/:carrier_id',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/Products.vue'),
        meta: {
            title: 'Agent Carrier Products'
        },
    },
    {
        name: "AgentAccounts",
        path: '/agents/accounts',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/AgentAccounts/index.vue'),
        meta: {
            permission: ['SuperAdmin', "Exec", "Staff"],
            title: 'Agent Account Balances'
        },
    },
    {
        name: "AgentBalanceDetail",
        path: '/agents/:agentcode/account_balance',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/AgentAccounts/AgentBalance.vue'),
        meta: {
            title: 'Agent Account Balance'
        },
    },
    {
        name: "UserBalanceDetail",
        path: '/user/account_balance',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/AgentAccounts/UserBalance.vue'),
        meta: {
            title: 'My Account Balance'
        },
    },
    {
        name: "AgentDetail",
        path: '/agents/:agentcode',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/AgentDetail.vue'),
        meta: {
            title: 'Agent Detail'
        },
    },
    {
        name: "AgentDetail_SalesRep",
        path: '/agents/:agentcode',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/SalesRep/AgentDetail.vue'),
        meta: {
            title: 'Agent Detail'
        },
    },
    {
        name: "AgentDetail_AgencyOwner",
        path: '/agents/:agentcode',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/AgencyOwner/AgentDetail.vue'),
        meta: {
            title: 'Agent Detail'
        },
    },
    {
        name: "AgentDetail_SuperAdmin",
        path: '/agents/:agentcode',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/SuperAdmin/AgentDetail.vue'),
        meta: {
            title: 'Agent Detail'
        },
    },
    {
        name: "AgentDetail_Exec",
        path: '/agents/:agentcode',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/SuperAdmin/AgentDetail.vue'),
        meta: {
            title: 'Agent Detail'
        },
    },
    {
        name: "AgentDetail_Staff",
        path: '/agents/:agentcode',
        component: () => import( /* webpackChunkName: "agent" */ '../views/Agent/SuperAdmin/AgentDetail.vue'),
        meta: {
            title: 'Agent Detail'
        },
    },
]

export default routes