<script>
/**
 * @docs https://vuejs.org/v2/guide/render-function.html#Complete-Example
 */
const getChildrenTextContent = (children) => {
    return children.map(function(node) {
        return node.children ?
            getChildrenTextContent(node.children) :
            node.text
    }).join('')
}

const vTabsSubComponents = [
    'v-tab',
    'v-tabs-slider',
    'q-tab-dropdown',
]

const isTabTag = (tag) => vTabsSubComponents.includes(tag)
const isTabItem = (tag) => tag === 'v-tab-item'

/**
 * This component is used as v-tabs, but tabs become v-select on mobile.
 */
export default {
    props: {
        value: {}
    },

    data() {
        return {
            ownActiveTabIndex: 0,
        }
    },

    computed: {
        activeTabIndex: {
            get() {
                if (this.$props.value === undefined) { return this.ownActiveTabIndex }

                return this.$props.value
            },
            set(val) {
                this.ownActiveTabIndex = val
                this.$emit('input', val)
            }
        },

        tabs() {
            return this.$slots.default
                .filter((n) => isTabTag(n.componentOptions ? n.componentOptions.tag : ''))
        },

        isMobile() { return this.$vuetify.breakpoint.smAndDown; },

        tabsText() {
            return this.tabs
                .filter((tab) => tab.componentOptions)
                .map((tab) => getChildrenTextContent(tab.componentOptions.children).trim())
        },
        selectValue: {
            get() {
                return this.tabsText[this.activeTabIndex]
            },
            set(val) {
                this.activeTabIndex = this.tabsText.findIndex((t) => t === val)
            }
        },
    },

    methods: {
        createSelect(h) {
            return h('v-select', {
                class: "q-tabs__select mt-0 pt-0 theme--dark",
                props: {
                    ...this.$attrs,
                    value: this.selectValue,
                    dark: true,
                    menuProps: { dark: true },
                    items: this.tabsText,
                },
                on: {
                    ...this.$listeners,
                    input: (val) => { this.selectValue = val }
                }
            })
        },

        createTabs(h) {
            return h('v-tabs', {
                    props: {
                        ...this.$attrs,
                        value: this.activeTabIndex,
                    },
                    on: {
                        ...this.$listeners,
                        change: (val) => { this.activeTabIndex = val }
                    }
                },
                this.$slots.default.filter((n) => isTabTag(n.componentOptions ? n.componentOptions.tag : ''))
            )
        },

        createTabsItems(h) {
            return h(
                'v-tabs-items', { props: { value: this.activeTabIndex } },
                this.$slots.default.filter((n) => isTabItem(n.componentOptions ? n.componentOptions.tag : ''))
            )
        }
    },

    render(h) {
        return h('div', { class: 'q-tabs' },
            [
                // Create tabs (and select) and tabs items divided instead of using them all inside v-tabs.
                // This way we can show tabs items along with select on mobile.
                this.isMobile ? this.createSelect(h) : this.createTabs(h),

                this.createTabsItems(h),

                this.$slots.default
                .filter((n) => !isTabTag(n.componentOptions ? n.componentOptions.tag : '') && !isTabItem(n.componentOptions ? n.componentOptions.tag : ''))
            ])
    },
}
</script>
<style lang="scss">
.q-tabs {
    width: 100%;

    &__select {
        background-color: #1e1e1e;

        .v-select__slot {
            height: 48px;
            padding: 0 1rem;
        }

        .v-input__slot {
            margin: 0;
        }

        .v-text-field__details {
            display: none;
        }

        .v-input__append-inner {
            margin: 0 !important;
            align-self: center !important;
        }
    }
}
</style>