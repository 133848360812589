<template>
    <div>
        <v-menu
            class="q-resource-menu"
            offset-y
            :class="{ 'v-menu--fullscreen': isMobile }"
            :max-width="isMobile ? '100%' : 400"
            :nudge-top="isMobile ? '-12px': 0"
            transition="slide-y-transition"
        >
            <template v-slot:activator="{ on }">
                <v-btn small text fab v-on="on">
                    <v-icon>fas fa-th</v-icon>
                </v-btn>
            </template>

            <v-container class="q-resource-menu__buttons-container grey lighten-5" :fluid="isMobile">
                <v-row align="center" no-gutters style=" background-color:#fff;">
                    <v-col cols="6" sm="4" v-for="(link, k) in links" :key="k" class="pa-1 d-flex justify-space-around">
                        <v-card dark flat v-ripple @click="doLink(link)" target="_blank" class="pt-4 pb-1 text-center" :color="link.color" style="width:100%; height:auto;">
                            <v-icon center style="font-size:32px !important; width:90%;" large>{{ link.icon }}</v-icon>
                            <p class="pt-3 caption font-weight-bold">{{ link.title }}</p>
                        </v-card>
                    </v-col>
                    
                    <v-col cols="6" sm="4" class="pa-1 d-flex justify-space-around ">
                        <v-card dark flat v-ripple target="_blank" href="https://quility.com" class="pt-4 pb-1 text-center" color="q_green_1" style="width:100%; height:auto;">
                            <v-img :src="require('@/assets/images/QuilityLogoSymbolWhiteRGB.svg')" max-height="32px" aspect-ratio="2" contain class="aligncenter" />
                            <p class="pt-3 caption font-weight-bold">Quility.com</p>
                        </v-card>
                    </v-col>
                    <!---
                    <v-col cols="6" sm="4" class="pa-1 d-flex justify-space-around ">
                        <v-card dark flat v-ripple to="/my-quility" class="pt-4 pb-1 text-center" color="q_green_1" style="width:100%; height:auto;">
                            <v-img :src="require('@/assets/images/QuilityLogoSymbolWhiteRGB.svg')" max-height="32px" aspect-ratio="2" contain class="aligncenter" />
                            <p class="pt-3 caption font-weight-bold">My Quility</p>
                        </v-card>
                    </v-col>
                -->
                </v-row>
            </v-container>
        </v-menu>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI';
import QShopAccountCodes from '@/components/utils/QShopCodes';

export default {
    name: 'QResourceMenu',

    props: {
        links: { type: Array, defualt: [] },
    },

    components: {
        QShopAccountCodes,
    },

    data() {
        return {};
    },

    computed: {
        isMobile() { return this.$vuetify.breakpoint.xs; },
    },

    methods: {
        doLink(link) {
            QuilityAPI.logActivty('AppMenu', link.title, this.$route.path, {});
            if (typeof link.function === 'function') {
                link.function();
            } else if (typeof link.function === 'string') {
                this[link.function]()
            } else {
                window.open(link.href, '_blank');
            }
        },
        OptSSO() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$router.push('/opt-mobile-app');
            } else {
                (
                    QuilityAPI.getOptSsoUrl().then((json) => {
                        if (json.Success) {
                            window.open(json.Message, '_blank');
                        } else {
                            this.showError(json.Message);
                        }
                    }).catch((err) => {
                        this.showError(err);
                    })
                );
            }
        },
        gotoShopInstructions() {
            this.showShopPrelude = false;
            this.$router.push('/quility-shop');
        },
    },
};
</script>

<style lang="scss">
.v-menu--fullscreen {
  width: 100%;
  left: 0;
  margin-top: 5px;
}

.q-resource-menu {
    &__buttons-container {
        max-height: calc(100vh - 80px);
        overflow-y: auto;
    }
}
</style>
