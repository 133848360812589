<template>
    <DashboardMobile v-if="isMobile" />
    <v-container v-else fluid class="px-5 grey lighten-5">
        <dashboard-corporate v-if="hasDivision(['Corporate'])" />
        <dashboard-field v-if="hasDivision(['Field'])" />
    </v-container>
</template>

<script>
import DashboardCorporate from '@/views/Dashboard/DashboardCorporate.vue'
import DashboardField from '@/views/Dashboard/DashboardField.vue'
import DashboardMobile from '@/views/Dashboard/DashboardMobile.vue'

export default {
    name: 'Dashboard',
    props: {
        to: {
            type: String,
            default: null
        },
        baseUrl: {
            type: String,
            default: null
        }
    },

    computed: {
        isMobile() { return this.$vuetify.breakpoint.xs; },
    },
    components: {
        DashboardCorporate,
        DashboardField,
        DashboardMobile,
    }
}
</script>
