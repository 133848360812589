<template>
    <v-dialog width="400" v-model="search" class="search__dialog">
        <v-card light class="pa-4">
            <v-text-field label="Search terms" ref="searchfield" v-model="keywords" v-on:keyup="performSearch" filled autofocus></v-text-field>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-row class="mx-1">
                <v-spacer />
                <v-btn large deprssed color="primary" dark right @click="searchResults">
                    Search<v-icon small right>fas fa-search</v-icon>
                </v-btn>
            </v-row>
            <div v-if="search_results.agents.data.length > 0 || search_results.pages.data.length > 0 || search_results.docs.data.length > 0" class="quick-results">
                <div class="q-subtitle pt-5">
                    <h5>Quick Results</h5>
                </div>
                <v-list dense two-line>
                    <v-list-item @click="loadAgent(result)" v-for="(result, k) in search_results.agents.data" :key="k">
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ result.LeadershipLevel }}</v-list-item-subtitle>
                            <v-list-item-title class="q_blue_1--text"><strong>{{ result.AgentName }}</strong></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon small>fas fa-user-alt</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="loadPage(result)" v-for="(result, k) in search_results.pages.data" :key="k">
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ result.category_name }}</v-list-item-subtitle>
                            <v-list-item-title class="q_blue_1--text"><strong>{{ result.title }}</strong></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon small v-if="result.external_url && result.external_url != 'internal'">fas fa-external-link-alt</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="viewDoc(result)" v-for="(result, k) in search_results.docs.data" :key="k">
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ result.CategoryName }}</v-list-item-subtitle>
                            <v-list-item-title class="q_blue_1--text"><strong>{{ result.DocTitle }}</strong></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import SearchResult from '@/store/Models/SearchResult'

export default {
    props: {},
    data: function() {
        return {
            keywords: null,
            search_results: {
                pages: {
                    data: [],
                    meta: {}
                },
                agents: {
                    data: [],
                    meta: {}
                },
                docs: {
                    data: [],
                    meta: {}
                },
            }
        }
    },
    computed: {
        'search': {
            set(v) {
                this.$store.commit('updateShowSearch', v);
            },
            get() {
                return this.$store.state.show_search;
            }
        },
        /*'search_results': function() {
            return SearchResult.all()
        },*/
        'loading': function() {
            return this.$store.state.entities.search_result.loading
        }
    },
    methods: {
        loadPage: function(result) {
            if (result.external_url && result.external_url != 'internal') {
                window.open(
                    result.external_url,
                    '_blank' // <- This is what makes it open in a new window.
                );
            } else {
                this.$router.push('/page/' + result.slug);
            }
            this.search = false;
        },
        loadData: debounce(function() {
            var g = this
            QuilityAPI.getSearchResults(null, { search: this.keywords, itemsPerPage: 4, page: 1 }).then(function(json) {
                g.search_results = json;
            });
            this.firstPull = false;
        }, 500),
        performSearch: function(e) {
            if (e.keyCode === 13) {
                this.searchResults();
            }
            if (this.keywords == null || this.keywords == '') {
                //this.show_results = false
            } else {
                this.loadData();
            }
        },
        searchResults: function() {
            this.active = false;
            this.$store.commit('updateShowSearch', false);
            this.$router.push({ name: 'SearchResults', query: { keyword: this.keywords } }).catch(err => { g.showError("Whoops! " + err) })
        },
        loadAgent(result) {
            this.$router.push('/agents/' + result.AgentCode);
            this.search = false;
        },

        viewDoc: function(doc) {
            window.open(doc.ViewLink, "_blank")
        },
    },
    watch: {
        'search': function(newV) {
            if (newV) {
                SearchResult.create({ data: {} });
                this.keywords = ''
            }
        }
    },
    components: {

    }
}

</script>
