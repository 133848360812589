<template>
    <v-dialog 
        scrollable 
        transition="scale-transition" 
        class="elevation-0 admin-notice__dialog" 
        :persistent="is_persistent" 
        v-model="dialog" 
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
    >
        <v-col cols="12" class="pa-0">
            <v-card class="cms" max-height="500" v-for="current_notice in current_notices" :key="current_notice.id">
                <v-card-title class="q-subhead primary" primary-title>
                    <span class="q_white-text">{{ current_notice.title }}</span>
                </v-card-title>
                <v-card-text v-html="current_notice.merged_content" class="pa-5 white mb-0">
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="white">
                    <span v-if="show_history" class="pl-2"><small>{{noticeDate(current_notice)}}</small></span>
                    <v-spacer></v-spacer>
                    
                    <v-btn v-if="show_acknowledge_btn(current_notice)" color="primary" @click="acknowledged(current_notice.id)">
                        Acknowledge
                        <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
                        <v-icon v-else small right>fas fa-check</v-icon>
                    </v-btn>
                    
                    <v-btn v-if="current_notice.link_text" color="primary" depressed large @click="acknowledged(current_notice.id, current_notice.link)">
                        {{ current_notice.link_text }}
                        <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
                        <v-icon v-else small right>fas fa-external-link-alt</v-icon>
                    </v-btn>
                    
                </v-card-actions>
            </v-card>
            <v-card v-if="current_notices.length < 1">
                <v-card-text class="pa-5">
                    No notifications to show.
                </v-card-text>
            </v-card>
        </v-col>
    </v-dialog>
</template>
<script>
import PopupNotice from '@/store/Models/PopupNotice'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QAdminNotices",
    props: ['preview', 'show_history'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            dialog: (this.preview && this.preview.show) || this.show_history,
            notices: []
        }
    },
    mounted: function() {
        var g = this;
        this.notices = this.$store.state.entities.popup_notice.admin_notices.filter(notice => {
            //check startDate
            if(notice.startDate != null && new Date(notice.startDate) > new Date())
                return false

            //check endDate
            if(notice.endDate != null && new Date(notice.endDate) < new Date())
                return false
            
            //check active
            if(notice.active != 1)
                return false

            return true
        });

        if (this.notices.length > 0) {
            this.dialog = true;
        }
    },
    computed: {
        loading: function() {
            return this.$store.state.entities.popup_notice.loading;
        },
        current_notices: function() {
            if(this.show_history) {
                let notices = this.user.popups_history.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
                return notices
            }
            return [this.notice]
        },
        notice: function() {
            if (this.notices.length > 0) {
                return this.notices[0]
            } else if(this.preview && this.preview.show) {
                let preview_notice = { id:0, title: this.preview.title, merged_content: this.preview.content, link_text: this.preview.link_text, link: this.preview.link, add_acknowledge_btn: this.preview.add_acknowledge_btn }
                return preview_notice
            }
            this.dialog = false;
            return false;
        },
        is_persistent: function() {
            if(this.show_history)
                return false
            if(this.preview && this.preview.show)
                return false
            return true
        }
    },
    methods: {
        acknowledged: function(id, link) {
            var g = this;
            
            //preview
            if(id == 0) {
                this.dialog = false
                if (link && link.indexOf('http') > -1) {
                    link = link.replace('{{AgentCode}}', g.user.Agent.AgentCode) //https://www.surveymonkey.com/r/SD532PY?AgentCode=[AgentCode_value]
                    window.open(link, '_tab')
                } else if(link) {
                    g.$router.push(link);
                }   
                return
            }

            QuilityAPI.acknowledgePopup(id).then(function() {
                g.notices.splice(0, 1);
                if (g.notices.length == 0) {
                    g.dialog = false;
                }
                if (typeof link == 'undefined') {
                    return
                }
                if (link.indexOf('http') > -1) {
                    link = link.replace('{{AgentCode}}', g.user.Agent.AgentCode) //https://www.surveymonkey.com/r/SD532PY?AgentCode=[AgentCode_value]
                    window.open(link, '_tab')
                } else {
                    g.$router.push(link);
                }
            });
        },
        noticeDate: function(notice) {
            return this.formatReadableDate(notice.updated_at)
        },
        show_acknowledge_btn: function(current_notice) {
            if(this.show_history)
                return false

            if(current_notice.link_text && current_notice.add_acknowledge_btn)
                return true
            
            if(current_notice.link_text)
                return false

            return true
        }
    },
    watch: {

    },
}

</script>
<style scoped>
</style>
