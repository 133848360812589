<template>
    <div>
        <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
                <v-btn small text fab v-on="on">
                    <v-badge v-if="totalAlerts > 0" color="warning" light :content="totalAlerts">
                        <v-icon small color="warning">fas fa-bell</v-icon>
                    </v-badge>
                    <v-icon v-else small>fas fa-bell</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item :to="{ name: 'SupportTickets'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-support-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ ticketNotices }} Support Ticket{{ ticketNotices != 1 ? 's' : '' }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'Pending Policy'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-new-business</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ pendingpolicycount }} Pending {{ pendingpolicycount != 1 ? 'policies' : 'policy' }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="notRole(['SalesRep', 'UnlicensedAgent'])" :to="{ name: 'PopupNoticeAdmin'}">
                    <v-list-item-icon>
                        <v-icon>fas fa-bell</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Popup Admin Notices</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showNotificationHistoryMenuItem" @click="showNotifications()">
                    <v-list-item-icon>
                        <v-icon>fas fa-bell</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>My Notifications</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="user.Agent.Flanking.length > 0" :to="{name:'User Profile'}">
                    <v-list-item-icon>
                        <v-icon>fas fa-exclamation-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Flanking Alert</v-list-item-title>
                </v-list-item>

                <!--<v-list-item @click="">
                    <v-list-item-title>You have 3 Notifications</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'LeadDashboard'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-leads-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>200 New Leads</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'New Business'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-new-business-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>34 ContractsNeed Attention</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'Engagement'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-engage-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>27 Upcoming Annual Reviews</v-list-item-title>
                </v-list-item>
                //-->
            </v-list>
        </v-menu>
        <q-admin-notices :show_history="show_popups_history" :key="historyKey"></q-admin-notices>

        <v-dialog v-model="showNpnForm"  scrollable :width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'">
            <v-card style="position:relative" class="q-agent-documents-table__docs-preview">
                <v-btn @click="showNpnForm = false" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-card-title>Information Required</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <page slug="npn-form"></page>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import SupportTicket from '@/store/Models/SupportTicket'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAdminNotices from '@/components/utils/QAdminNotices.vue'
import Page from '@/CMS/views/index.vue' 

export default {
    name: "QNotificationMenu",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            pendingpolicycount: 0,
            show_popups_history: false,
            historyKey: 1,
            showNpnForm: false
        }
    },
    mounted: function() {
        this.getpendingpolicycount()

        if(!this.user.Agent.IsFullyLicensed )
            this.showNpnForm = true
    },
    computed: {
        ticketNotices: function() {
            return SupportTicket.query().where('status', (value) => (value == 'solved' || value == 'pending')).count()
        },
        totalAlerts: function() {
            let total = this.ticketNotices + this.pendingpolicycount
            if(this.user.Agent.Flanking.length > 0)
                total ++
            return total
        },
        showNotificationHistoryMenuItem: function() {
            return this.user.popups_history && this.user.popups_history.length > 0
        }
    },
    methods: {
        getpendingpolicycount: function(){
            var g = this
            QuilityAPI.getAgentPolicyCountSinceLastVisit(this.user.AgentCode)
            .then((json) => {
                g.$set(g, 'pendingpolicycount', json.Count);
            })
        },
        showNotifications: function() {
            this.show_popups_history = true
            this.historyKey = Math.random()
        }
    },
    watch: {

    },
    components: {
        QAdminNotices,
        Page
    }
}

</script>
<style scoped>
</style>
