const routes = [{
        path: '/page/quility-u/',
        name: 'QuilityU',
    },
    {
        path: '/page/hand-book/',
        name: 'Handbook',
    },
    {
        path: '/resources/tools/',
        name: 'Tools',
        component: () => import( /* webpackChunkName: "resources" */ '../views/content/Tools.vue')
    },
    {
        path: '/page/marketing/',
        name: 'Marketing',
    },
    {
        path: '/page/benefits',
        name: 'Benefits',
    },
    {
        path: '/page/thrive',
        name: 'Thrive',
    },
    {
        path: '/page/connect',
        name: 'Connect',
    },
    {
        path: '/contests',
        name: 'Contests',
        component: () => import( /* webpackChunkName: "resources" */ '../views/content/Contests.vue')
    },
    {
        path: '/news',
        name: 'News',
        component: () => import( /* webpackChunkName: "resources" */ '../views/content/News.vue')
    },
    {
        path: '/quility-shop',
        name: 'Quility Shop',
        component: () => import( /* webpackChunkName: "resources" */ '../views/content/QuilityShop.vue')
    },
    {
        path: '/opt-mobile-app',
        name: 'Opt Mobile App',
        component: () => import( /* webpackChunkName: "resources" */ '../views/content/OptMobileApp.vue')
    }
]

export default routes
