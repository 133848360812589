<template>
    <div>
        <v-snackbar app small dense bottom right tile elevation="24" :color="color" v-model="show_snackbar" :timeout="null" vertical max-width="400" min-width="400">
            <v-progress-linear dark indeterminate v-if="loading"></v-progress-linear>
            <div v-for="(j, k) in jobs_q" :key="j.ID">
                <v-icon v-if="j.Status == 'Error'" color="red">fas fa-times-circle</v-icon>
                <v-progress-circular dark indeterminate v-else-if="j.Status == 'Running'" size="20" class="mr-1"></v-progress-circular>
                <v-icon small dark v-else-if="j.Status == 'Done'" left>fas fa-check</v-icon>
                <v-icon small dark v-else-if="j.Status == 'In Queue'" left>fas fa-stopwatch</v-icon>
                <span>{{ j.JobName }}</span>
                <a v-if="j.Status == 'Done'" @click="downloadExportFile(j)" class="ml-5">Download</a>
                <a v-if="j.Status == 'Error'" @click="downloadErrorFile(j)" class="ml-5">Download</a>
            </div>
            <v-btn text to="/user/my_exports">
                All Exports
            </v-btn>
            <v-btn small fab absolute top right @click=" show_snackbar=false">
                <v-icon small>fas fa-times</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QSnackbarJobs",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            show_snackbar: false,
            text: null,
            color: 'info',
            details: null,
            show_details: false,
            jobs_q: [],
            loading: false,
            interval_id: null
        }
    },
    mounted: function() {
        var g = this;
        //expects a object like this 
        //{
        //  method : "GET",
        //  path : "/api/private/agents,
        //  params : {},
        //  JobName : "Agent Export"
        //}
        EventBus.$on("dispatchJob", function(job) {
            //first dispatch the job to the server
            g.show_snackbar = true;
            g.loading = true;
            QuilityAPI.dispatchExportJob(job).then(function(json) {
                g.loading = false;
                if (json.data) {
                    g.jobs_q.push(json.data);
                } else {
                    g.showError("That's odd... the job wasn't returned.")
                }
                g.color = 'info';
            }).catch(function(error) {
                g.loading = false;
            })
        });
    },
    computed: {

    },
    methods: {
        "ping": function() {
            var g = this
            if (this.show_snackbar) {
                this.jobs_q.forEach(function(j) {
                    if (j.Status != "Done" && j.Status != "Error") {
                        QuilityAPI.pingJobStatus(j.ID).then(function(json) {
                            g.updateJobStatus(json.data);
                        })
                    }
                })
            }
        },
        "updateJobStatus": function(new_j) {
            this.jobs_q.forEach(function(j) {
                if (j.ID == new_j.ID) {
                    j.Status = new_j.Status;
                }
            })
        },
        downloadExportFile: function(j) {
            this.loading = true
            var g = this;
            QuilityAPI.downloadExportJob(j.ID)
                .then(function(resp) {
                    g.loading = false
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Export_' + j.JobName + '_' + j.ID + '.csv');
                    fileLink.setAttribute('target', '_new');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(err) {
                    g.loading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    //g.showError(err.status)
                });
        },
        downloadErrorFile: function(j) {
            this.loading = true
            var g = this;
            QuilityAPI.downloadBulkJobErrorLogFile(j.ID)
                .then(function(resp) {
                    g.loading = false
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Error_' + j.JobName + '_' + j.ID + '.txt');
                    fileLink.setAttribute('target', '_new');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(err) {
                    g.loading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    //g.showError(err.status)
                });
        },
    },
    watch: {
        'show_snackbar': function(new_v) {
            this.show_details = false;
            if (new_v) {
                this.interval_id = setInterval(this.ping, 10000)
            } else {
                this.jobs_q = []
                clearInterval(this.interval_id);
            }
        }
    },
}
</script>
<style scoped>
</style>