import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'

import Lead from '@/store/Models/Lead'
import AvailableLead from '@/store/Models/AvailableLead'
import Leaderboard from '@/store/Models/LeaderBoard'
import Event from '@/store/Models/Event'
import Support from '@/store/Models/Support'
import Agent from '@/store/Models/Agent'
import Application from '@/store/Models/Application'
import AgentProfile from '@/store/Models/AgentProfile'
import Category from '@/store/Models/Category'
import { Page, ContestPage, NewsPage, SupportPage, EventPage } from '@/store/Models/PageTypes'
//import SupportPage from '@/store/Models/SupportPage'
import SupportTicket from '@/store/Models/SupportTicket'
import Image from '@/store/Models/Image'
import SearchResult from '@/store/Models/SearchResult'
import User from '@/store/Models/User'
import PopupNotice from '@/store/Models/PopupNotice'
import PopupLog from '@/store/Models/PopupLog'
import NeedsAnalysisLead from '@/store/Models/NeedsAnalysisLead'
import Level from '@/store/Models/AgentManagement/Level'
import GlobalSessionData from '@/store/Models/GlobalSessionData'
import LeadOrder from '@/store/Models/LeadOrders/LeadOrder'
import LeadOrderLine from '@/store/Models/LeadOrders/LeadOrderLine'
import LeadAllocation from '@/store/Models/LeadOrders/LeadAllocation'
import LeadQuantity from '@/store/Models/LeadOrders/LeadQuantity'
import SummitNav from '@/store/Models/SummitNav'

VuexORM.use(VuexORMAxios, {
    axios,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

Vue.use(Vuex)

const database = new VuexORM.Database()
database.register(Lead)
database.register(AvailableLead)
database.register(Leaderboard)
database.register(EventPage)
database.register(Support)
database.register(Agent)
database.register(Application)
database.register(Category)
database.register(Page)
database.register(SupportPage)
database.register(Image)
database.register(SupportTicket)
database.register(SearchResult)
database.register(ContestPage)
database.register(NewsPage)
database.register(User)
database.register(PopupNotice)
database.register(PopupLog)
database.register(NeedsAnalysisLead)
database.register(AgentProfile)
database.register(Level)
database.register(GlobalSessionData)
database.register(LeadOrder)
database.register(LeadOrderLine)
database.register(LeadAllocation)
database.register(LeadQuantity)
database.register(SummitNav)

export default new Vuex.Store({
    plugins: [VuexORM.install(database)],
    state: {
        show_search: false,
        history_levels: []
    },
    mutations: {
        updateShowSearch(state, data) {
            state.show_search = data
        }
    },
    getters: {

    },
    actions: {

    }
})
