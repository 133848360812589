<template>
    <div>
        <q-score-card key="QScoreCardCloseratio" title="Close Ratio" api-endpoint="closeratio" drill-downLabel="Agent Breakdown" drill-down-route="Drilldown_Closeratio" card-style="production" :agent-code="agentCode" :filters="filters" format="Percentage" :default-goal=".3">
            Note: Close ratio is based on previous 6 weeks from the end of the business month.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardCloseratio",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
