<template>
    <v-list id="main-menu" dense>
        <v-row v-if="toggle !== undefined" class="mr-2" justify="end">
            <v-btn x-small color="#fff" depressed @click="handleToggle">
                <v-icon center color="q_agency_1" v-if="!toggle">fas fa-angle-double-right</v-icon>
                <v-icon center color="q_agency_1" v-if="toggle">fas fa-angle-double-left</v-icon>
            </v-btn>
        </v-row>
        <div v-for="(nav_item, k) in filtered_menu" :key="k" :is="nav_item.children.length == 0 ? 'menu-item' : 'menu-group'" :icon="nav_item.icon" :path="nav_item.to" :href="nav_item.href" :title="nav_item.label" :update="update">
            <sub-menu-item v-for="(nav_child, k) in nav_item.children" :key="k" small icon="" :path="nav_child.to" :href="nav_child.href">
                {{nav_child.label}}
            </sub-menu-item>
        </div>
        <menu-group v-if="hasPermission('manage:Content') && !isImpersonating" :update="update" icon="icon-q-content-editor" title="Content Editor" route="Admin">
            <sub-menu-item v-for="item in contentManagerMenu" :key="item.text" icon="fas fa-angle-right" small :route="item.route">
                {{ item.text }}
            </sub-menu-item>
        </menu-group>
        <menu-group v-if="hasPermission('manage:Forms') && !isImpersonating" :update="update" icon="icon-q-content-editor" title="Form Management" route="Admin">
            <sub-menu-item v-for="item in formManagerMenu" :key="item.text" icon="fas fa-angle-right" small :route="item.route">
                {{ item.text }}
            </sub-menu-item>
        </menu-group>
        <!--<menu-item icon="fas fa-tools" :click="toggleUtilityDrawer">Utility Drawer</menu-item>-->
    </v-list>
</template>
<script>
import MenuItem from './MenuItem.vue';
import MenuGroup from './MenuGroup.vue';
import SubMenuItem from './SubMenuItem.vue';

export default {
    props: {
        toggle: { type: Boolean, default: undefined },
    },
    data: function() {
        return {
            update: 0,
            toggle_left_nav: false
        }
    },
    methods: {
        allowDivision(item) {
            var g = this
            if (item.divisions == 'Any') {
                return true
            }
            return g.hasDivision(item.divisions)
        },
        collapse: function() {
            this.update++;
        },
        toggleMobileMenu: function(menus) {
            this.drawerMobile = !this.drawerMobile;
        },
        toggleRightMenu: function(menus) {
            this.drawerRight = !this.drawerRight;
        },
        handleToggle: function(v) {
            this.$emit('toggle-menu-updated', !this.toggle)
        }
    },
    computed: {
        filtered_menu: function() {
            var m = [];
            var g = this;
            this.user.nav_menu.forEach(function(item) {
                if (g.allowDivision(item)) {
                    var _item = { ...item }
                    _item.children = [];
                    item.children.forEach(function(child) {
                        if (g.allowDivision(child)) {
                            _item.children.push(child)
                        }
                    })
                    m.push(item);
                }
            })
            return m
        },
        contentManagerMenu() {
            if (!this.hasPermission('manage:Content') || this.isImpersonating) {
                return [];
            }

            return [
                { route: 'CMS', text: 'Pages' },
                { route: 'Categories', text: 'Categories' },
                { route: { name: 'CMSEditor', params: { page_id: 'new' } }, text: 'New Page' },
                { route: 'MediaLibrary', text: 'Media Library' },
                { route: 'DocumentLibrary', text: 'Document Library' },
                { route: 'CarrierPages', text: 'Carrier Pages' },
                { route: 'CarrierProducts', text: 'Carrier Products' },
                { route: { name: 'NavEditor' }, text: 'Nav Editor' },
                { route: { name: 'EditLevelsOfLeadership' }, text: 'Leadership Profiles' },
                { route: { name: 'EditCorporateStaff' }, text: 'Corporate Profiles' },
                { route: '/page/content-block-examples', text: 'Content Block Examples' },
            ];
        },
        formManagerMenu() {
            if (!this.hasPermission('manage:Forms') || this.isImpersonating) {
                return [];
            }

            return [
                { route: 'FML', text: 'Forms' },
                { route: { name: 'FMLEditor', params: { FormID: 'new' } }, text: 'New Form' },
            ];
        },
    },
    components: {
        MenuItem,
        MenuGroup,
        SubMenuItem
    }
}
</script>