<template>
    <v-app-bar flat dark clipped-left class="q-app-bar" :class="{ 'q-app-bar--mobile': isMobile }" :height="isMobile ? '74px' : '56px'">
        <div class="q-app-bar__content" :class="{ 'q-app-bar__content--mobile': isMobile }">
            <div class="q-app-bar__left">
                <v-app-bar-nav-icon small v-if="isTabletAndDown" @click.stop="toggleMobileMenu" />
                <q-resource-menu-field id="v-step-1" v-if="hasDivision(['Field'])" />
                <q-resource-menu-corporate id="v-step-1" v-if="hasDivision(['Corporate'])" />
            </div>
            <v-spacer />
            <v-toolbar-title style="height: 100%">
                <a href="/" style="height: 100%; display: block">
                    <img class="nav-logo" :src="logoPath" alt="Quility Dash Board" contain height="100%" />
                </a>
            </v-toolbar-title>
            <v-spacer />
            <div v-if="hasRole(['SuperAdmin']) && user.currentSetDivision && (!isTabletAndDown)" class="overline" style="width: 1px;">
                <div style="text-align: right; float: right; width: 200px; opacity: .5;" class="pr-6">
                    {{user.currentSetDivision}} DIVISION
                </div>
            </div>
            <div class="q-app-bar__right">
                <!-- Used for opening the right slider if we want it
            <v-btn small text fab @click.stop="toggleRightMenu">
                <v-icon>fas fa-th</v-icon>
            </v-btn>
            -->
                <q-summit-open-sidebar-button v-if="hasRole(['SuperAdmin', 'Staff', 'Exec']) || isImpersonating || hasPermission('agent:Summit')"></q-summit-open-sidebar-button>
                <q-search id="v-step-2" v-if="!isMobile"></q-search>
                <q-notification-menu id="v-step-3"></q-notification-menu>
                <q-user-menu id="v-step-3a" :show-avatar="!isMobile"></q-user-menu>
                <!---
            <div>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="startTour" icon v-bind="attrs" v-on="on">
                    <i class="fas fa-2x fa-info-circle"></i>
                  </v-btn>
                </template>
                <span>Quickly tour available functions </span>
              </v-tooltip>
            </div>
        -->
            </div>
        </div>
    </v-app-bar>
</template>
<script>
import QSearch from '../utils/QSearch';
import QUserMenu from './QUserMenu';
import QNotificationMenu from './QNotificationMenu';
import QResourceMenuCorporate from './QResourceMenu/QResourceMenuCorporate';
import QResourceMenuField from './QResourceMenu/QResourceMenuField';
import QSummitOpenSidebarButton from '../summit/sidebar/QSummitOpenSidebarButton.vue';
import VerticalLogo from '@/assets/images/QuilityLogoVerticalWhiteRGB.svg';
import HorizontalLogo from '@/assets/images/QuilityLogoHorizontalWhiteRGB.svg';

export default {
    name: "QAppbar",
    props: ['icon', 'text', 'route', 'click'],

    computed: {
        isMobile() { return this.$vuetify.breakpoint.xs; },
        isTabletAndDown() { return this.$vuetify.breakpoint.smAndDown; },

        logoPath() { return this.isMobile ? VerticalLogo : HorizontalLogo; },
    },

    methods: {
        startTour() {
            this.$refs.tour.startTour(this.$route.name);
        },
        toggleMobileMenu: function() {
            this.$emit('mobileMenu')
        },
        toggleRightMenu: function() {
            this.$emit('rightMenu')
        },
        clicked: function() {
            if (typeof this.route == 'string') {
                this.$router.push({ name: this.route }).catch(err => { g.showError("Whoops! " + err) })
            }
            if (typeof this.click == 'function') {
                this.click();
            }
        }
    },
    components: {
        QSearch,
        QUserMenu,
        QNotificationMenu,
        QResourceMenuField,
        QResourceMenuCorporate,
        QSummitOpenSidebarButton,
    }
}
</script>
<style scoped>
.v-tour__target--relative {
    margin-top: 0px !important;
}
</style>
<style lang="scss">
.q-app-bar {
    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &--mobile {
            padding: 4px 0;
        }
    }

    &__left,
    &__right {
        display: flex;
        justify-content: center;
        align-items: center;

        &>* {
            margin: 0 4px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &--mobile {
        .q-app-bar {
            &__content {
                align-items: flex-end;
            }

            &__left,
            &__right {
                align-items: flex-end;
            }
        }

        .v-btn {
            height: 24px !important;
            width: 24px !important;
        }
    }
}
</style>