<template>
    <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="drawer" disable-resize-watcher :width="drawer ? undefined : 0" left @input="updateValue()">
        <div class="menu-controls">
            <v-btn small text fab @click="close" class="text-right" width="100%">
                <div style="width: 100%" class="text-right px-7">
                    <v-icon>fas fa-times</v-icon>
                </div>
            </v-btn>
        </div>

        <div class="px-2" v-if="$vuetify.breakpoint.xs">
            <QSearchInput class="pa-0" />                
        </div>

        <main-navigation-list></main-navigation-list>
    </v-navigation-drawer>
</template>

<script>
import MainNavigationList from './MainNavigationList.vue'
import QSearchInput from '@/components/utils/QSearchInput.vue';

export default {
    props: ['value'],
    data: function() {
        return {
            drawer: false,
            utilDrawer: false,
            mini: !!this.$vuetify.breakpoint.sm
        }
    },
    methods: {
        updateValue() {
            this.$emit('input', this.drawer)
        },
        toggleUtilityDrawer() {
            this.utilDrawer = !this.utilDrawer
        },
        close() {
            this.drawer = false;
            this.updateValue();
        }
    },
    watch: {
        'value': function(newV) {
            this.drawer = newV
        },
        $route(to, from) {
            if (this.$vuetify.breakpoint.xs) {
                this.drawer = false;
            }
        }
    },
    components: {
        MainNavigationList,
        QSearchInput
    }
}
</script>
