<template>
    <div>
        <v-btn small text fab @click="doShowDrawer = !doShowDrawer">
            <v-icon small>icon-q-summit-icon</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { store } from './store/sidebar-store'

export default {
    computed: {
        doShowDrawer: {
            get() { return store.doShowSummitSidebar },
            set(val) { store.doShowSummitSidebar = val }
        }
    }
}
</script>