<template>
    <v-card 
        id="impersonate-banner" 
        class="q-impersonate-banner" 
        :class="{ 'q-impersonate-banner--hidden': hidden }"
        @click="hidden = false"
    >
        <v-banner single-line sticky color="rgb(255, 235, 59, 0.8)" class="d-flex text-left body-2">
            <span class="text-wrap">
                <span class="mr-5">
                    You are currently impersonating <b>{{ $auth.auth_user.name}}</b>.
                </span>
                <v-btn depressed small color="q_blue_1" dark @click="revertImpersonation">
                    Switch back <v-icon class="pl-3" color="q_blue_5">fas fa-undo-alt</v-icon>
                </v-btn>

                <v-btn depressed text small @click.stop="hidden = true">
                    <v-icon>fas fa-eye-slash</v-icon>
                </v-btn>  
            </span>
        </v-banner>
    </v-card>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

const LOCAL_STORAGE_HIDDEN_KEY = 'q-impersonate-banner-hidden'

export default {
    name: "QImpersonateBanner",
    data() {
        return { hidden: false }
    },

    mounted() {
        if (localStorage) {
            const saved = localStorage.getItem(LOCAL_STORAGE_HIDDEN_KEY) || "false"
            this.hidden = JSON.parse(saved)
        }
    },

    methods: {
        revertImpersonation() {
            QuilityAPI.revertImpersonation()
                .then(function(json) {
                    if (json.success == true) {
                        //redirect the admin back to the detail page for that agent. 
                        document.location.href = process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                    }
                })
        },
    },

    watch: {
        hidden(val) {
            if (localStorage) { localStorage.setItem(LOCAL_STORAGE_HIDDEN_KEY, val) }
        }
    }
}
</script>

<style lang="scss" scoped>
#impersonate-banner {
    margin: 8px 55px;
    position: fixed;
    z-index: 50;
    background-color: transparent;
}

.q-impersonate-banner {
    &--hidden {
        overflow: hidden;
        max-height: 8px;
        margin: 0 !important;
        width: 100%;
    }
}
</style>
