<template>
    <v-footer color="blue-grey" class="white--text q-footer">
        <v-card style="min-width:100%;" class="mb-3">
            <v-expand-transition>
                <div v-show="showBanner" class="reimagine pl-0 pr-0 ml-0 mr-0 ">
                    <v-btn fab x-small absolute top right dark rounded-circle color="#1a1e3f" @click="showBanner = !showBanner" style="border-radius:50%">
                        <v-icon x-small>{{ showBanner ? 'fas fa-times' : 'fas fa-times' }}</v-icon>
                    </v-btn>
                    <a href="/page/reimagine-2022-resources">
                        <img src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/Reimagine-2022_22-02-24_HQ-Ad_Desktop_1920x66_2NuHyD.png" class=" d-none d-sm-flex" style="max-width:100%; margin:0 auto;">
                        <img src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/Reimagine-2022_22-02-24_HQ-Ad_Mobile_415x127_ikTfAW.png" class="d-flex d-sm-none" style="max-width:100%; margin:0 auto;">
                    </a>
                </div>
            </v-expand-transition>
        </v-card>
        <v-row class="d-flex justify-space-around flex-wrap pl-12 pr-3">
            <div class="spacer" style="max-width:1px;"></div>
            <div>
                <span>{{release.Version}}{{release.MinorVersion}}</span> -
                <router-link to="/page/hq-release-notes">Release Notes</router-link>
            </div>
            <div>
                <span v-if="user.currentSetDivision">
                    <span v-if="user.currentSetDivision == 'Field'">Symmetry</span>
                    <span v-else-if="user.currentSetDivision == 'Corporate'">Asurea</span>
                    <span v-else>{{user.currentSetDivision}}</span>
                </span>
                <span class="pl-1">&copy; 2021</span>
            </div>
        </v-row>
    </v-footer>
</template>
<script>
import ReleaseMeta from '@/ReleaseMeta.json'

export default {
    data() {
        return {
            showBanner: true,
            release: ReleaseMeta
        }
    }
}

</script>
