<template>
    <v-list-item @click.stop="clicked" @mouseover="hover = true" @mouseleave="hover = false">
        <v-list-item-content>
            <v-list-item-title class="icon-indent">
                <v-icon v-if="icon" left :small="small">{{icon_class}}</v-icon>
                <slot></slot>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
export default {
    props: ['icon', 'text', 'route', 'click', 'href', 'selected', 'small', 'path'],
    data: () => ({
        hover: false,
        offset: 1000,
    }),


    computed: {
        icon_class: function() {
            if (this.icon.indexOf("icon-q") == 0) {
                if (this.hover || this.selected) {
                    return this.icon + "-select";
                }
            }
            return this.icon;
        }
    },
    methods: {
        clicked: function() {
            if (typeof this.path == 'string') {
                if (this.validURL(this.path)) {
                    // this check needs to be nested
                    // to prevent hq from opening the
                    // external link and redirecting
                    // to it which causes a 404
                    if (!this.href) {
                        window.open(this.path, '_blank');
                    }
                } else {
                    this.$router.push(this.path).catch(err => { console.log("Whoops! " + err) })
                }
            }
            if (typeof this.href == 'string') {
                window.open(this.href, '_blank');
            }
            if (typeof this.route == 'string') {
                this.$router.push({ name: this.route }).catch(err => { console.log("Whoops! " + err) })
            }
            if (typeof this.route == 'object') {
                this.$router.push(this.route).catch(err => { console.log("Whoops! " + err) })
            }
            if (typeof this.click == 'function') {
                this.click();
            }
        },
        validURL: function(url) {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(url);
        }
    },
    watch: {}
}

</script>
