<template>
    <v-text-field v-model="keywords" autofocus :placeholder="placeholder" @keyup.enter="goToSearchResult" />
</template>
<script>
export default {
    props: {
        placeholder: { type: String, default: "Search" },
    },

    data() {
        return {
            keywords: "",
        };
    },

    methods: {
        goToSearchResult() {
            this.$router.push({ name: "SearchResults", query: { keyword: this.keywords } }).catch((err) => {
                this.showError(`Whoops! ${err}`);
            });
        },
    },
};
</script>