<template>
    <v-list-group class="main_menu_item" v-model="is_open" :prepend-icon="icon_class" @mouseover="hover = true" @mouseleave="hover = false">
        <v-icon slot="prependIcon" small>{{icon_class}}</v-icon>
        <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title style="font-weight:600;">
                    {{ title }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
        <div style="margin-left:45px">
            <slot></slot>
        </div>
    </v-list-group>
</template>
<script>
export default {
    name: "MenuGroup",
    props: ['icon', 'title', 'route', 'click', 'href', 'selected', 'update'],
    data: () => ({
        hover: false,
        is_open: false
    }),
    computed: {
        icon_class: function() {
            if (this.icon.indexOf("icon-q") == 0) {
                if (this.hover || this.selected) {
                    return this.icon + "-select";
                }
            }
            return this.icon;
        }
    },
    methods: {
        clicked: function() {
            if (typeof this.href == 'string') {
                window.open(this.href, '_blank');
            }
            if (typeof this.route == 'string') {
                this.$router.push({ name: this.route }).catch(err => { console.log("Whoops! " + err) })
            }
            if (typeof this.click == 'function') {
                this.click();
            }
        }
    },
    watch: {
        'update': function(newV) {
            this.is_open = false
        }
    },
    components: {

    }
}

</script>
