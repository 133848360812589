<template>
    <q-submenu :title="title" :titlelink="titlelink" :baseUrl="baseUrl" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "My Stats",
            baseUrl: "/my_stats",
            titlelink: [],
        }
    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }

            return submenu;
        },
        buttons: function() {
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                return [{
                        label: "Production",
                        to: "/my_stats/scorecard/new_business",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-1"
                    }, {
                        label: "Recruiting",
                        to: "/my_stats/scorecard/recruiting",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-2"
                    }, {
                        label: "Leads",
                        to: "/my_stats/scorecard/leads",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-3"
                    },
                    {
                        label: "Leaderboards",
                        to: "/my_stats/leaderboards",
                        icon: "fas fa-arrow-alt-circle-right",
                    },
                    {
                        label: "Event Stats",
                        to: "/my_stats/scorecard/events",
                        icon: "fas fa-arrow-alt-circle-right",
                    },
                    {
                        label: "HQ Stats",
                        to: "/my_stats/scorecard/hq",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    },
                    {
                        label: "Summit",
                        to: "/my_stats/scorecard/summit",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }
                ]
            }
            if (this.hasRole(['AgencyOwner'])) {
                return [{
                        label: "Production",
                        to: "/my_stats/scorecard/new_business",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-1"
                    }, {
                        label: "Recruiting",
                        to: "/my_stats/scorecard/recruiting",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-2"
                    },
                    {
                        label: "Leaderboards",
                        to: "/my_stats/leaderboards",
                        icon: "fas fa-arrow-alt-circle-right",
                    },
                    {
                        label: "Event Stats",
                        to: "/my_stats/scorecard/events",
                        icon: "fas fa-arrow-alt-circle-right",
                    },
                    {
                        label: "HQ Stats",
                        to: "/my_stats/scorecard/hq",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    },
                    {
                        label: "Summit",
                        to: "/my_stats/scorecard/summit",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }
                ]
            } else {
                return [{
                        label: "Production",
                        to: "/my_stats/scorecard/new_business",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-1"
                    }, {
                        label: "Recruiting",
                        to: "/my_stats/scorecard/recruiting",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-2"
                    },
                    {
                        label: "HQ Stats",
                        to: "/my_stats/scorecard/hq",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }
                ]
            }
        }
    },
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>
