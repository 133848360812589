const routes = [{
    path: '/agency-building/structure/:agent_code?',
    name: 'AgencyStructure',
    // route level code-splitting
    // this generates a separate chunk (leads.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "agency_building" */ '../views/AgencyBuilding/AgencyStructure.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "AgencyOwner", "Staff"],
        title: 'Agency Structure'
    },
}, {
    path: '/agency-building/directory',
    name: 'AgentDirectory',
    // route level code-splitting
    // this generates a separate chunk (leads.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "agency_building" */ '../views/AgencyBuilding/AgentDirectory.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "AgencyOwner", "Staff", 'SalesRep'],
        title: 'Agent Directory'
    },
}, {
    path: '/agency-building/recruiting_stats',
    name: 'MyRecruitingStats',
    component: () => import( /* webpackChunkName: "agency_building" */ '../views/AgencyBuilding/RecruitingStats.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "AgencyOwner", "Staff"],
        title: 'My Recruiting Stats'
    },
}, {
    path: '/agency-building/:agentcode/recruiting_stats',
    name: 'RecruitingStats',
    component: () => import( /* webpackChunkName: "agency_building" */ '../views/AgencyBuilding/RecruitingStats.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "AgencyOwner", "Staff"],
        title: 'Recruiting Stats'
    },
}, {
    path: '/agency-building/ad-insertion-orders',
    name: 'QAdInsertionOrders',
    component: () => import( /* webpackChunkName: "agency_building" */ '../views/AgencyBuilding/AdInsertionOrders.vue'),
    meta: {
        permission: ['AgencyOwner', 'SuperAdmin', "Exec", "AgencyOwner", "Staff"],
        title: 'Ad Insertion Orders'
    },
}, {
    path: '/agency-building/ad-insertion-markets',
    name: 'QAdInsertionMarkets',
    component: () => import( /* webpackChunkName: "agency_building" */ '../views/AgencyBuilding/AdInsertionMarkets.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "AgencyOwner", "Staff"],
        title: 'Ad Insertion Markets'
    },
},{
    name: "AgentCalendarLinks",
    path: '/agency-building/AgentCalendarLinks',
    component: () => import( /* webpackChunkName: "agent" */ '../views/AgencyBuilding/AgentCalendarLinks.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "AgencyOwner", "Staff"],
        title: 'DFL Calendar Links'
    },
}]

export default routes
