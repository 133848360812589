<template>
    <QResourceMenu :links="links" />
</template>
<script>
import QResourceMenu from './QResourceMenu';

export default {
    components: {
        QResourceMenu,
    },

    computed: {
        links() {
            const QuilityUURL = this.hasRole(['Exec', 'SuperAdmin', 'Staff']) ? 'https://quility.lightspeedvt.com/' : 'https://auth.quility.com/samlp/z6P8HtF55HXSgXbAT1anWb2BMM2Fdko0';
            if (this.hasRole('UnlicensedAgent')) {
                return [{
                        href: 'https://calendars.sfglife.com/wp-login.php',
                        title: 'Events',
                        color: 'q_engagement_1',
                        icon: 'icon-events',
                    },
                    // { href: "https://auth.quility.com/samlp/z6P8HtF55HXSgXbAT1anWb2BMM2Fdko0", title: "Quility U", color: "q_blue_1", icon: "icon-quility-u" },
                    {
                        href: QuilityUURL,
                        title: 'Quility U',
                        color: 'q_blue_1',
                        icon: 'icon-quility-u',
                    },
                    {
                        href: 'https://quilityonboarding.com',
                        title: 'Onboarding Portal',
                        color: 'q_agency_1',
                        icon: 'icon-q-onboarding',
                    },
                    {
                        href: 'https://www.concursolutions.com/nui/signin',
                        title: 'Travel Portal',
                        color: 'q_support_1',
                        icon: 'icon-q-travel',
                    },
                    {
                        href: "https://sfg.ninjadialer.com/#/login/vmquility",
                        title: "Virtual Mentor",
                        color: "q_resources_1",
                        icon: "icon-q-oc"
                    },
                ];
            }
            return [

                {
                    title: 'Opt!',
                    color: 'q_leads_1',
                    icon: 'icon-q-leads',
                    function: 'OptSSO',
                },
                {
                    href: 'https://auth.quility.com/samlp/IAIG38uVluNjOS7x5IHPvOUYP2TzcwWv?RelayState=https://pipepasstoigo.ipipeline.com/default.aspx?gaid=6503',
                    title: 'iPipeline',
                    color: 'q_new_b_1',
                    icon: 'icon-ipipeline',
                },
                {
                    href: 'https://auth.quility.com/samlp/IAIG38uVluNjOS7x5IHPvOUYP2TzcwWv?RelayState=https://formspipe.ipipeline.com/?GAID=6503',
                    title: 'Formpipe',
                    color: 'q_new_b_2',
                    icon: 'icon-q-formpipe',
                },
                // { href: "https://quility.com", title: "Quility.com", color: "q_new_b_1", icon: "icon-q-new-business" },

                {
                    href: 'https://quility.auth0.com/samlp/OgzWlZHuZ84QVlorfklBFO3iSfPrFMzp',
                    title: 'Quility Tech',
                    color: 'q_wb_blue',
                    icon: 'icon-q-qtech',
                },
                // { href: "https://auth.quility.com/samlp/z6P8HtF55HXSgXbAT1anWb2BMM2Fdko0", title: "Quility U", color: "q_blue_1", icon: "icon-quility-u" },
                {
                    href: QuilityUURL,
                    title: 'Quility U',
                    color: 'q_blue_1',
                    icon: 'icon-quility-u',
                },
                {
                    href: 'https://quilityonboarding.com',
                    title: 'Onboarding Portal',
                    color: 'q_agency_1',
                    icon: 'icon-q-onboarding',
                },
                /* { href: "https://www.amplifinity.net/Account/Login?ReturnUrl=%2fPartners%3fprogramGuid%3da56f88ff-5dfe-4968-8406-b842821fb78d&programGuid=a56f88ff-5dfe-4968-8406-b842821fb78d", title: "Care to Share", color: "q_resources_1" }, */
                {
                    href: 'https://sfg.ninjadialer.com/#/login/vmquility',
                    title: 'Virtual Mentor',
                    color: 'q_resources_1',
                    icon: 'icon-q-oc',
                },
                {
                    href: 'https://sfg.ninjadialer.com/#/login/quilitylogin',
                    title: 'Ninja Dialer ',
                    color: 'q_resources_1',
                    icon: 'icon-q-dialer',
                },
                /* { href: "https://sfgcrm3.com/index.aspx?ReturnUrl=%2f", title: "Virtual Assistant", color: "q_resources_1", icon: "icon-q-v-assistant" }, */
                /* { href: "https://sfglife.com/agentadmin", title: "Owner's Circle", color: "q_resources_1", icon: "icon-q-oc" }, */
                // { href: "https://webapps.everplans.com/#/auth/sign_in", title: "Everplans", color: "q_resources_1", icon: "icon-q-everplans" },
                // { href: "https://store.sfglife.com", title: "SFG Store", color: "q_support_1", icon: "icon-q-shopping" },

                {
                    href: 'https://www.concursolutions.com/nui/signin',
                    title: 'Travel Portal',
                    color: 'q_support_1',
                    icon: 'icon-q-travel',
                },
                {
                    href: 'https://shopquility.com/login',
                    title: 'Quility Store',
                    color: 'q_support_1',
                    icon: 'icon-q-shopping',
                },
            ];
        },
    },
};

</script>
