<template>
    <div>
        <v-progress-linear indeterminate v-if="loading" color="q_leads_4"></v-progress-linear>
        <q-leaderboard-data-table :show-all="showAll" :title="the_title" :subtitle="subtitle" :rows="rows" :leaderboard-data="leaderboard_data.data" :agentPosition="agentPosition.data" :start-date="displayStartDate" :end-date="displayEndDate"></q-leaderboard-data-table>
    </div>
</template>
<script>
import QLeaderboardDataTable from './QLeaderboardDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QLeaderboardDataTableWrapper",
    props: {
        'agentCode': {
            type: String,
            default: null
        },
        'type': {
            type: String,
            default: null
        },
        'timeFrame': {
            type: String,
            default: null
        },
        'title': {
            type: String,
            default: null
        },
        'subtitle': {
            type: String,
            default: null
        },
        'showAll': {
            type: Boolean,
            default: false
        },
        'rows': {
            type: Number,
            default: 5
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        }
    },
    data: function() { // data internal to component (not available outside)
        return {
            leaderboard_data: {
                data: [],
            },
            agentPosition: {
                data: {},
            },
            loading: false,
            displayStartDate: this.startDate,
            displayEndDate: this.endDate
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        the_title: function() {
            return this.title + ' ' + this.titleCase(this.timeFrame) + ' Leaderboard'
        }
    },
    methods: {
        loadData: function() {
            var g = this
            this.loading = true;
            var filters = {
                startDate: this.startDate,
                endDate: this.endDate
            }
            QuilityAPI.loadLeaderboard(this.timeFrame, this.type, this.agentCode, this.rows, this.showAll, filters).then(function(json) {
                g.$set(g.leaderboard_data, 'data', json.data)
                g.$set(g.agentPosition, 'data', json.meta.agent_position)
                g.$set(g, 'displayStartDate', json.meta.start_date)
                g.$set(g, 'displayEndDate', json.meta.end_date)
                g.loading = false
                g.$emit('loaded');
            }).catch(function() {
                g.loading = false
            })
        }
    },
    watch: {
        'timeFrame': function(newV) {
            this.$nextTick(this.loadData)
        },
        'startDate': function(newV) {
            this.displayStartDate = newV;
            this.$nextTick(this.loadData)
        }
    },
    components: {
        QLeaderboardDataTable
    }
}

</script>
<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

</style>
