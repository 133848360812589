<template>
    <div>
        <!-- This is a dummy element to help position the tour step for the main navigation menu. //-->
        <div id="v-step-4" style="position:absolute;left:-5px;top:150px;"></div>
        <v-row justify="center" align="stretch">
            <v-col cols="12" id="v-step-5">
                <v-card class="pa-5" elevation="3">
                    <v-row justify="center">
                        <!--opens demo menu -->
                        <v-menu offset-y v-if="submenu.length > 0">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" depressed absolute top right>
                                    <v-icon id="v-step-9">fas fa-ellipsis-v</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(sub, key) in submenu" :key="key" @click.stop="goTo(sub)" @mouseover="hover = true" @mouseleave="hover = false">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span class="item-title">
                                                {{ sub.label }}
                                            </span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <!--/opens demo menu -->
                        <v-col cols="12" lg="8" class="d-flex align-content-center flex-wrap px-5" style="position:relative">
                            <q-page-slider slug="homepage-slider-corporate"></q-page-slider>
                            <v-btn to="/cms/editor/607" small light fab absolute top right v-if="hasPermission(['manage:Content'])">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-row justify="center" align="stretch">
                                <v-col cols="12" sm="6" lg="12">
                                    <q-contest-list type="Contest"></q-contest-list>
                                </v-col>
                                <v-col cols="12" sm="6" lg="12" class="pt-0 mt-0">
                                    <q-news-list type="Contest"></q-news-list>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <!-- Stats -->
        <!-- Leaderboards-->
        <!--<v-row wrap>
            <v-col cols="12">
                <q-leaderboard-explorer></q-leaderboard-explorer>
            </v-col>
        </v-row>//-->
        <q-page slug="homepage-content-corporate"></q-page>
        <v-overlay opacity=".8" :value="!data_loaded">
            Loading...
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import QContestList from '@/components/lists/QContestList.vue';
import QNewsList from '@/components/lists/QNewsList.vue';
import QPageSlider from '@/components/utils/QPageSlider.vue';
import QPage from '@/CMS/views/index.vue';

export default {
    name: 'Dashboard',

    components: {
        QContestList,
        QNewsList,
        QPageSlider,
        QPage,
    },

    props: {
        to: {
            type: String,
            default: null,
        },
        baseUrl: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            message: `Your last logon was ${new Date().toLocaleString()}`,
            counter: 0,
            sheet: false,
            data_loaded: true,
        };
    },

    mounted() {
    // if the user has not already seen this tour it will automatically start.
        this.maybeAutoStartDemo('DemoCorpDashboard');
    },

    computed: {
        submenu() {
            const submenu = [
                { label: 'Help', to: '/support' },
                {
                    label: 'Start Demo',
                    fun() { this.startDemo(); },
                },
            ];
            return submenu;
        },
    },

    methods: {
    // starts the demo tour when the option in the ellipsis menu is clicked.
        startDemo() {
            this.$tours.DemoCorpDashboard.start();
        },

        goTo(target) {
            if (target.anchor) {
                if (!this.isBase) {
                    this.$router.push(this.baseUrl + target.anchor);
                } else {
                    this.$vuetify.goTo(target.anchor, { easing: 'easeInOutCubic', offset: 80 });
                }
            }
            if (target.to) {
                this.$router.push(target.to);
            }
            if (target.href) {
                window.open(target.href, '_blank');
            }
            if (target.fun) {
                target.fun();
            }
        },
    },
};
</script>

<style>
h4 { margin-bottom: 10px; }

h1.with-short-rule::after,
h2.with-short-rule::after,
h3.with-short-rule::after,
h4.with-short-rule::after,
h5.with-short-rule::after {
    padding: .2em;
    display: block;
    width: 6%;
    height: 1px;
    content: " ";
    background-color: #DAF3F0;
    margin: 4px 0 8px 0;
}
</style>
