<template>
    <v-container fluid class="ma-0" :class="[color]">
        <slot>
            <v-row>
                <v-col class="py-0" cols="12" sm="8">
                    <slot name="left"></slot>
                </v-col>
                <v-col class="py-0" cols="12" sm="4">
                    <slot name="right"></slot>
                </v-col>
            </v-row>
        </slot>
    </v-container>
</template>

<script>
export default {
    props: {
        color: { type: String, default: 'q_green_1' },
    },
};
</script>
