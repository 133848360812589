import { getInstance } from "./index";
import User from '@/store/Models/User'

export const authGuard = (router) => {
    //router is our router instance so we can access it later down the chain 
    return (to, from, next) => {
        const authService = getInstance();
        const fn = () => {
            // If the user is authenticated, continue with the route
            if (authService.isAuthenticated) {
                if (to.meta.permission) {
                    const user = User.query().first();
                    if (typeof to.meta.permission == 'object') {
                        for (var i = 0; i < to.meta.permission.length; i++) {
                            if (user != null && user.permissions.indexOf(to.meta.permission[i]) > -1) {
                                return next();
                            }
                            if (authService.auth_user['http://quility.com/roles'].indexOf(to.meta.permission[i]) > -1) {
                                return next();
                            }
                        }
                    } else {
                        if (authService.auth_user['http://quility.com/roles'].indexOf(to.meta.permission) > -1) {
                            return next();
                        }
                        if (user != null && user.permissions.indexOf(to.meta.permission) > -1) {
                            return next();
                        }
                    }
                    return next('/403');
                } else {
                    //check if there is a custom view to route them to 
                    var roleRoute = router.resolve({ name: to.name + "_" + authService.auth_user['http://quility.com/roles'][0], params: to.params });
                    if (roleRoute.resolved.matched.length > 0) {
                        roleRoute.resolved.matched[0].path = to.path;
                        roleRoute.resolved.matched[0].params = to.params;
                        return next(roleRoute.resolved.matched[0]);
                    }
                    return next();
                }
            }

            // Otherwise, log in
            authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
        };

        // If loading has already finished, check our auth state using `fn()`
        if (!authService.loading) {
            return fn();
        }

        // Watch for the loading property to change before we check isAuthenticated
        authService.$watch("loading", loading => {
            if (loading === false) {
                return fn();
            }
        });
    };
};
