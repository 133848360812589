<template>
    <v-card class="mx-auto ma-1 " color="" elevation="3" height="100%">
        <QDataTableHeader color="deep-purple">
            <v-row>
                <v-col cols="12" sm="6">
                    <p class="q-display-3 white--text">
                        Leaderboards
                    </p>
                </v-col>
                <v-col cols="12" sm="6" :class="$vuetify.breakpoint.sm ? 'text-right' : ''">
                    <v-switch class="d-inline-flex ma-0" color="white" dark inset v-model="weeklyperiod" :label="date_period"></v-switch>
                </v-col>                
            </v-row>
        </QDataTableHeader>

        <v-col cols="12">
            <QTabs class="q-text-left lighest_grey" background-color="q_symm_blue" dark>
                <v-tab @click="logTabActivty('My Agency Leaderboards')" v-if="hasRole(['AgencyOwner'])">My Agency Leaderboards</v-tab>
                <v-tab @click="logTabActivty('Agency Leaderboards')" v-if="hasRole(['SalesRep']) || user.Agent.LeadershipLevel == 'Agency Owner'">Agency Leaderboards</v-tab>
                <v-tab @click="logTabActivty('Symmetry Leaderboards')">Symmetry Leaderboards</v-tab>
                <v-tab @click="logTabActivty('Quility Rx Personal')">Quility Rx Personal</v-tab>
                <v-tab @click="logTabActivty('Quility Rx Baseshop')">Quility Rx Base Shop</v-tab>
                <v-tab-item class="lighest_grey" v-if="hasRole(['AgencyOwner'])">
                    <v-row class="pa-3" justify="center" align="start">
                        <v-col cols="12" sm="12" md="6" class="text-center ">
                            <q-leaderboard-data-table-wrapper :title="'Base Shop'" :subtitle="user.AgentName + ' Agency'" :agent-code="user.AgentCode" type="my_baseshop" :time-frame="date_period" v-on:loaded="maybeAutoStartDemo('DemoLeaderboards')"></q-leaderboard-data-table-wrapper>
                            <p>Leaderboard for your baseshop agents' personal production.</p>
                            <v-btn depressed class=" mt-4" v-if="hasRole(['AgencyOwner'])" color="primary" to="/my_stats/leaderboards">View Full List</v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center" v-if="hasRole(['AgencyOwner']) && user.LeadershipLevel != 'Agency Owner'">
                            <q-leaderboard-data-table-wrapper :title="'Total Agency'" :subtitle="user.AgentName + ' Agency'" :agent-code="user.AgentCode" type="my_agency" :time-frame="date_period"></q-leaderboard-data-table-wrapper>
                            <p>Leaderboard for total agency personal production.</p>
                            <v-btn depressed class="mt-4" v-if="hasRole(['AgencyOwner'])" color="primary" to="/my_stats/leaderboards">View Full List</v-btn>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item class="lighest_grey" v-if="hasRole(['SalesRep']) || user.Agent.LeadershipLevel == 'Agency Owner'">
                    <v-row class="pa-3" justify="center" align="start">
                        <v-col cols="12" sm="12" md="6" class="text-center" v-if="hasRole(['SalesRep'])">
                            <q-leaderboard-data-table-wrapper title="Base Shop" :subtitle="user.BaseShopAgencyOwner.AgentName + ' Agency'" :agent-code="user.AgentCode" type="baseshop" :time-frame="date_period"></q-leaderboard-data-table-wrapper>
                            <p>Leaderboard for your upline agency's personal production.</p>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center" v-if="user.Agent.LeadershipLevel == 'Agency Owner'">
                            <q-leaderboard-data-table-wrapper :title="'Agency Owners'" :subtitle="user.DirectAgencyOwner.AgentName + ' Agency'" :agent-code="user.AgentCode" type="agencyowners" :time-frame="date_period"></q-leaderboard-data-table-wrapper>
                            <p>Leaderboard for Agency Owners (aggregate) within the {{user.DirectAgencyOwner.AgentName}} Agency</p>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center">
                            <q-leaderboard-data-table-wrapper title="Direct Agency" :subtitle="user.DirectAgencyOwner.AgentName + ' Agency'" :agent-code="user.AgentCode" type="agency" :time-frame="date_period"></q-leaderboard-data-table-wrapper>
                            <p>Leaderboard for personal production within the {{user.DirectAgencyOwner.AgentName}} Agency</p>
                        </v-col>
                        <v-col cols="12" class="text-center">
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item class="lighest_grey">
                    <q-top-5-leaderboards :time-frame="date_period"></q-top-5-leaderboards>
                </v-tab-item>
                <v-tab-item>
                    <q-quility-rx-leaderboards :time_frame="date_period" type="personal"></q-quility-rx-leaderboards>
                </v-tab-item>
                <v-tab-item>
                    <q-quility-rx-leaderboards :time_frame="date_period" type="baseshop"></q-quility-rx-leaderboards>
                </v-tab-item>
            </QTabs>
        </v-col>
    </v-card>
</template>
<script>
import QTop5Leaderboards from '@/components/datatables/QTop5Leaderboards.vue'
import QLeaderboardDataTableWrapper from '@/components/datatables/QLeaderboardDataTableWrapper.vue'
import QDataTableHeader from '../../components/datatables/base/QDataTableHeader.vue'
import QTabs from '../../components/navigation/QTabs.vue'
import QQuilityRxLeaderboards from '@/components/datatables/QQuilityRxLeaderboards.vue'

export default {
    data() {
        return {
            weeklyperiod: false
        }
    },
    mounted: function() {
        this.maybeAutoStartDemo('DemoSupport')
    },
    computed: {
        'date_period': function() {
            return this.weeklyperiod ? 'Weekly' : 'Monthly';
        }

    },
    components: {
        QTop5Leaderboards,
        QLeaderboardDataTableWrapper,
        QDataTableHeader,
        QTabs,
        QQuilityRxLeaderboards
    }
}

</script>
<style scoped>
.v-tour__target--relative {
    margin-top: 80px;
}

</style>
